import React from "react";
import logo from "./logo.png";
import "./index.scss";
const Header = () => {
  return (
    <header>
      <a href="https://www.ilex-app.de">
        <img src={logo} alt="Ilex App Logo" />
      </a>
      <nav>
        <ul>
          <a href="https://app.ilex-app.de">
            <li className="button">Anmelden</li>
          </a>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import appPreview from "./app-preview.png";
import playStoreBadge from "./google-play-badge.png";
import appStoreBadge from "./apple-badge.svg";
import "./index.scss";

const Home = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    fetch("https://app.ilex-app.de/v1/events?offset=0&limit=3")
      .then(res => res.json())
      .then(data => setEvents(data));
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <div className="hero">
          <h1 style={{ fontWeight: "300" }}>Ilex App</h1>
          <img src={appPreview} alt="" />
          <h2>Die Gemeinde auf dem Handy</h2>
          <div id="download-badges">
            <a
              href="https://apps.apple.com/us/app/ilex-app/id1492756055?l=de&ls=1"
              style={{ justifyContent: "flex-end" }}
            >
              <img alt="Jetzt im App Store" src={appStoreBadge} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=de.farbenfroh.ilexapp&pcampaignid=landing"
              style={{ justifyContent: "flex-start" }}
            >
              <img
                alt="Jetzt bei Google Play"
                src={playStoreBadge}
                style={{ height: "85px" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

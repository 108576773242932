import React from "react";
import "./index.scss";

const AppPrivacy = () => {
  return (
    <div>
      <h1>Datenschutz</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese App benutzen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <br />
      <h3>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h3>
      <p>
        Die Datenverarbeitung in dieser Applikation erfolgt durch den Betreiber.
        Dessen Kontaktdaten können Sie dem Impressum dieser App entnehmen.
      </p>
      <br />
      <h3>Wie erfassen wir Ihre Daten?</h3>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Formular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch beim Benutzen der App durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
        Betriebssystem oder Uhrzeit des Aufrufs). Die Erfassung dieser Daten
        erfolgt automatisch, sobald Sie diese App benutzen.
      </p>
      <br />
      <h3>Wofür nutzen wir Ihre Daten?</h3>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        App zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <br />
      <h3>Welche Rechte haben Sie bezüglich Ihrer Daten?</h3>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
        Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
        der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Außerdem haben Sie das Recht, unter bestimmten Umständen die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
        „Recht auf Einschränkung der Verarbeitung“.
      </p>
      <br />
      <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
      <h3>Externes Hosting</h3>
      <p>
        Diese App greift über eine Schnittstelle auf unsere Datendienste zu,
        welche bei einem externen Dienstleister gehostet (Hoster) werden. Die
        personenbezogenen Daten, die durch diese Aufrufe erfasst werden, werden
        auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
        IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen und sonstige Daten, die technisch
        generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
        gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
        lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen
        Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erfüllung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>
        <b>Abschluss eines Vertrages über Auftragsverarbeitung</b>
      </p>
      <p>
        Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir
        einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.
      </p>
      <br />
      <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Firma Farbenfroh Web und App Entwicklung GmbH nimmt den Schutz Ihrer
        persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen
        Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese App benutzen, werden verschiedene personenbezogene Daten
        erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
        identifiziert werden können. Die vorliegende Datenschutzerklärung
        erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
        erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <br />
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>Farbenfroh Web und App Entwicklung GmbH</p>
      <p>Tannenweg 15</p>
      <p>32609 Hüllhorst</p>
      <p>Telefon: 057412379800</p>
      <p>E-Mail: office@sei-farbenfroh.de</p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <br />
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <br />
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>
        <b>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO).
        </b>
      </p>
      <p>
        <b>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </b>
      </p>
      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <br />
      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <br />
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese App nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte bei der Anfrage unserer Datendienste eine SSL-
        bzw. TLS-Verschlüsselung.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <br />
      <h3>Auskunft, Löschung und Berichtigung</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
        Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>
      <ul>
        <li>
          {" "}
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <br />
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <br />
      <h2>4. Datenerfassung durch die App</h2>
      <h3>Server-Log-Dateien</h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die bei der Nutzung unserer
        Datendienste automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
        <li> verwendetes Betriebssystem</li>
        <li> Hostname des zugreifenden Rechners</li>
        <li> Uhrzeit der Serveranfrage</li>
        <li> IP Adresse</li>
      </ul>
      <br />
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
      </p>
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Der Betreiber der App hat ein berechtigtes Interesse an der
        technisch fehlerfreien Darstellung, der Sicherheit und der Optimierung
        seiner Dienste – hierzu müssen die Server-Log-Files erfasst werden.
      </p>
      <br />
    </div>
  );
};

export default AppPrivacy;

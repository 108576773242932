import React from "react";
import "./index.scss";
import FAQ from "./faq";
const Faq = () => {
  return (
    <main>
      <h1>
        Hi! <br />
        Wie können wir dir helfen?
      </h1>
      <FAQ
        question={
          "In meinem Abfuhrkalender fehlen die Gelben Säcke. Wie kann ich sie anzeigen?"
        }
        answer={
          "Die Gelben Säcke werden in der Gemeinde Hüllhorst an zwei unterschiedlichen Tagen abgeholt. Unter 'Einstellungen' > 'Mitteilungen' > 'Bezirk' muss der passende Bezirk ausgewählt werden. Die Region westlich der Straße 'Roter Bach' ist der Bezirk West die andere Seite der Bezirk Ost. Im zweifel hilft es den Bezirk einmal hin und her zu wechseln, danach kann der Abfuhrkalender neu geladen werden und die Gelben Säcke werden ebenfalls angezeigt."
        }
        isOpen={true}
      />
      <FAQ
        question={
          "Ich vertrete einen Verein/ein Unternehmen/eine Gruppe. Wie kann ich eine Veranstaltung erstellen?"
        }
        answer={
          "Um Veranstaltungen erstellen zu können benötigst du einen Account. Du kannst dich unter https://app.ilex-app.de registrieren. Nach deiner Anmeldung bekommst du eine E-Mail, in der du deinen Account bestätigen musst. Anschließend kannst du dich in der Web Oberfläche mit deinen gewählten Zugangsdaten anmelden."
        }
      />
      <FAQ
        question={"Okay. Und wie erstelle ich nun eine Veranstaltung?"}
        answer={
          "Nachdem du einen Account für dich erstellt hast, hast du die Möglichkeit eine Seite für deine Organisation zu erstellen. Dazu klickst du nach der Anmeldung im Menü auf 'Seiten' und dann auf das '+'. Sobald du eine Seite erstellt hast, kannst du unter 'Veranstaltungen' > '+' eine neue Veranstaltung erstellen."
        }
      />
      <FAQ
        question={
          "Der Kurzname meiner Seite ist rot und ich kann keine Seite erstellen. Was ist da los?"
        }
        answer={
          "Der Kurzname dient später zu einer leichten Suche im Browser - es stellt den Link auf das Profil der Seite dar. Aus diesem Grund sind keine Leerzeichen erlaubt."
        }
      />
    </main>
  );
};

export default Faq;

import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Imprint from "./sites/Imprint";
import Privacy from "./sites/Privacy";
import Faq from "./sites/Faq";
import Home from "./sites/Home";
import AppPrivacy from "./sites/AppPrivacy";

const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <Switch>
          <Route path="/impressum">
            <Imprint />
          </Route>
          <Route path="/datenschutz">
            <Privacy />
          </Route>
          <Route path="/app-privacy">
            <AppPrivacy />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
